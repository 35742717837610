import React from 'react';
import './homeCard.css'

const HomeCard = ({image, title, description, price}) => {
    return(
        <div className='card'
            data-aos="flip-left"
            data-aos-duration="800">
            <div className="card-content">
                <img
                    className='card-image'
                    src={image}
                    />
                <h2 className="card-title">{title}</h2>
                <p className="card-text">{description}</p>
                <p className="card-price">{price}</p>
            </div>
        </div>
    )
}

export default HomeCard;