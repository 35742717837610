const GalleryData = [
  {
    title: "Best Production Practices",
    banner: "Best-1.jpg",
    imgs: ["Best-1.jpg", "Best-2.jpg", "Best-3.jpg", "Best-4.jpg"],
  },
  {
    title: "Plantation Practices",
    banner: "Plantation-14.jpg",
    imgs: [
      "Plantation-1.jpg",
      "Plantation-2.jpg",
      "Plantation-3.jpg",
      "Plantation-4.jpg",
      "Plantation-5.jpg",
      "Plantation-6.jpg",
      "Plantation-7.jpg",
      "Plantation-8.jpg",
      "Plantation-9.jpg",
      "Plantation-10.jpg",
      "Plantation-11.jpg",
      "Plantation-12.jpg",
      "Plantation-13.jpg",
      "Plantation-14.jpg",
      "Plantation-15.jpg",
      "Plantation-16.jpg",
      "Plantation-17.jpg",
      "Plantation-18.jpg",
      "Plantation-19.jpg",
      "Plantation-20.jpg",
      "Plantation-21.jpg",
      "Plantation-22.jpg"
    ],
  },
  {
    title: "Manufacturing",
    banner: "Manufac-14.jpg",
    imgs: [
      "Manufac-1.jpg",
      "Manufac-2.jpg",
      "Manufac-3.jpg",
      "Manufac-4.jpg",
      "Manufac-5.jpg",
      "Manufac-6.jpg",
      "Manufac-7.jpg",
      "Manufac-8.jpg",
      "Manufac-10.jpg",
      "Manufac-11.jpg",
      "Manufac-12.jpg",
      "Manufac-13.jpg",
      "Manufac-14.jpg",
      "Manufac-16.jpg",
      "Manufac-17.jpg",
      "Manufac-18.jpg",
      "Manufac-21.jpg"
    ],
  },
  {
    title: "CSR Activities",
    banner: "CSR-2.jpg",
    imgs: [
      "CSR-1.jpg",
      "CSR-2.jpg",
      "CSR-3.jpg"
    ],
  },
];

export default GalleryData;
