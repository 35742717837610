import React from "react";
import "./productSet.css";
import ProductCard from "../productCard/productCard";

const ProductSet = ({ title, products }) => {
  return (
    <div className="product-set">
      <div className="product-set-title">
        <h2 className="product-sec--title">{title}</h2>
      </div>
      <div className="product-card-set">
        {products.map((product, index) => {
          return (
            <ProductCard
              key={index}
              image={product.img}
              title={product.title}
              description={product.desc}
              price={product.price}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ProductSet;
