import "./home.css";
import Hero from "../../components/hero/hero";
import heroImg from "../../_resources/img/hero.png";
import HomeCard from "../../components/homeCard/homeCardComponent";
import BlogCard from "../../components/blogCard/BlogCard";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { Navigation } from "swiper/modules";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import "./home.css";
import "../../_resources/icon/Increase Heart Health.png";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import productData from "../../data/productData";
import { useNavigate } from "react-router-dom";

const looseWeight = require("../../_resources/icon/Lose Weight.png");
const heartHelth = require("../../_resources/icon/Increase Heart Health.png");
const bloodSugar = require("../../_resources/icon/Sugar Blood Level.png");
const boostEnergy = require("../../_resources/icon/Boost Energy.png");
const mentalHealth = require("../../_resources/icon/Mental Health.png");
const boneHealth = require("../../_resources/icon/Bone Health.png");
const antiBac = require("../../_resources/icon/Antibacterial.png");
const AntiCancer = require("../../_resources/icon/Anticancer.png");

export default function Home() {
  const [flexDirection, setFlexDirection] = useState("column");
  const [card1FlexDirection, setCard1FlexDirection] = useState("column");
  const [widthBlogCard, setWidthBlogCard] = useState("100%");
  const [widthBlogCard2, setWidthBlogCard2] = useState("100%");
  const [contentWidth, setContentWidth] = useState("100%");

  const navigate = useNavigate();

  const handleImageClick = (section) => {
    navigate("/gallery", { state: { section } }); 
  };

  useEffect(() => {
    const updateStyles = () => {
      if (window.matchMedia("(min-width: 950px)").matches) {
        setCard1FlexDirection("column");
        setFlexDirection("row");
        setWidthBlogCard("30%");
        setWidthBlogCard2("90%");
        setContentWidth("90%");
      } else if (window.matchMedia("(min-width: 540px)").matches) {
        setCard1FlexDirection("row");
        setFlexDirection("row");
        setWidthBlogCard("30%");
        setWidthBlogCard2("90%");
        setContentWidth("90%");
      } else {
        setCard1FlexDirection("column");
        setFlexDirection("column");
        setWidthBlogCard("90%");
        setWidthBlogCard2("90%");
      }
    };

    updateStyles(); // Initial check
    window.addEventListener("resize", updateStyles); // Listen for resize

    return () => window.removeEventListener("resize", updateStyles); // Cleanup on unmount
  }, []);

  var bestSellers = [9,13,2,3];

  return (
    <>
      {/* Hero Section */}
      <Hero
        img={heroImg}
        heading={"Welcome to"}
        heading2={"JayCinnamon"}
        subHeading={"best cinnamon ever"}
      />

      {/* First Section */}
      <section className="home--sec1">
        <h3 className="title">RECOMMENDED</h3>
        <h1 className="subTitle">Best Selling Products</h1>

        {/* Card Component */}
        <div className="home-swiper-section">
          <button className={`homeSec--paginationBtn homeSec--prev`}>
            <FontAwesomeIcon icon={faAngleLeft} />
          </button>
          <Swiper
            modules={[Navigation]}
            spaceBetween={5}
            slidesPerView={1}
            navigation={{
              prevEl: `.homeSec--prev`,
              nextEl: `.homeSec--next`,
            }}
            breakpoints={{
              540: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
            }}
            className="home--slider"
          >
            {bestSellers.map((pid, index) => {
              const product = productData.filter((item) => item.id === parseInt(pid))[0];
              console.log(index);
              return (
                <SwiperSlide>
                  <HomeCard
                    image={`${process.env.PUBLIC_URL}/_resources/Img/Products/${product.img}`}
                    title={product.title}
                    description={product.desc}
                    price={`${product.price} LKR`}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
          <button className={`homeSec--paginationBtn homeSec--next`}>
            <FontAwesomeIcon icon={faAngleRight} />
          </button>
        </div>
        <Link to="/products" className="shopAllButton" data-aos="fade-in">View All</Link>
      </section>

      {/* Second Section */}
      <section className="home--sec2">
        <h3 className="title">NUTRITION FOR RESULTS</h3>
        <h1 className="subTitle">Start Your Transformation</h1>
        <div className="home--sec2--img" data-aos="fade-up"
            data-aos-duration="800">
          <div className="sec2--img--container">
            <img
              src={`${process.env.PUBLIC_URL}/_resources/Img/Products/Bvg-Black-4.jpg`}
              className="sec2--img"
            />
            <p>Cinnamon Beverage Range</p>
          </div>
          <div className="sec2--img--container">
            <img
              src={`${process.env.PUBLIC_URL}/_resources/Img/Products/Cut-Alba-4.jpg`}
              className="sec2--img"
            />
            <p>Cut Cinnamon and Powder</p>
          </div>
          <div className="sec2--img--container">
            <img
              src={`${process.env.PUBLIC_URL}/_resources/Img/Products/Bark-30ml-4.jpg`}
              className="sec2--img"
            />
            <p>Cinnamon Bark Oil</p>
          </div>
        </div>
      </section>

      {/* Third Section */}
      <section className="home--sec3">
        <div className="sec3--text">
          <h3 className="title">LATEST NEWS</h3>
          <h1 className="subTitle" style={{ color: "#f7f7f7" }}>
            Profood Exhibition 2023 at BMICH
          </h1>
          <p className="sec3--paragraph">
            JCC successfully participated in the most recent “Profood”
            exhibition held at BMICH and ensured its success. We really
            appreciate all the visitors who visited our booth, bought our
            products, and encouraged us.
          </p>
          {/* <button className="learnMoreButton">Learn More</button> */}
        </div>
        <div className="sec3--img--container">
          <img
            src={`${process.env.PUBLIC_URL}/_resources/Img/Home/Sec-3 -- img1.jpeg`}
            className="sec3--img"
          />
          <img
            src={`${process.env.PUBLIC_URL}/_resources/Img/Home/Sec-3 -- img2.jpeg`}
            className="sec3--img"
          />
        </div>
      </section>

      {/* Fourth Section */}
      <section className="home--sec4">
        <h3 className="title">Benefits</h3>
        <h1 className="subTitle">When you use Cinnamon Products</h1>

        <div className="sec4--cards--container">
          <div className="sec4--cards">
            <img src={looseWeight} className="sec4--imgs" />
            <p>Lose Weight</p>
          </div>
          <div className="sec4--cards">
            <img src={heartHelth} className="sec4--imgs" />
            <p>Increase Heart Health</p>
          </div>
          <div className="sec4--cards">
            <img src={bloodSugar} className="sec4--imgs" />
            <p>Maintain Blood Sugar Level</p>
          </div>
          <div className="sec4--cards">
            <img src={boostEnergy} className="sec4--imgs" />
            <p>Boost Energy</p>
          </div>

          <div className="sec4--cards">
            <img src={mentalHealth} className="sec4--imgs" />
            <p>Boost Mental Health</p>
          </div>
          <div className="sec4--cards">
            <img src={boneHealth} className="sec4--imgs" />
            <p>Maintain Bone Health</p>
          </div>
          <div className="sec4--cards">
            <img src={antiBac} className="sec4--imgs" />
            <p>Anti-Bacterial Effects</p>
          </div>
          <div className="sec4--cards">
            <img src={AntiCancer} className="sec4--imgs" />
            <p>Anti-Cancer Potential</p>
          </div>
        </div>
      </section>

      {/* Fifth Section */}
      {/* <section className="home--sec5">
        <h3 className="title">Blogs</h3>
        <h1
          className="subTitle"
          style={{ color: "#f7f7f7", textAlign: "center" }}
        >
          History and Benefits of Cinnnamon
        </h1>
        <div className="sec5--items">
          <BlogCard
            image={`${process.env.PUBLIC_URL}/_resources/Img/Home/Sec-4 -- B1.jpg`}
            flexDirection={card1FlexDirection}
            width={widthBlogCard}
            contentWidth={contentWidth}
            blogNumber={"1"}
            title={"Health Benefits of Cinnamon"}
            description={
              "Cinnamon is a valuable spice that has so many health Benefits. Modern scientists have found that organic compound cinnanmaldehyde which includes in cinnmamon is responsible for most of its benefits. These are some examples for health benefits"
            }
            date={"September 18, 2023"}
          />
          <div className="sec5--items--divide">
            <BlogCard
              image={`${process.env.PUBLIC_URL}/_resources/Img/Home/Sec-4 -- B2.jpeg`}
              flexDirection={flexDirection}
              width={widthBlogCard2}
              blogNumber={"2"}
              title={"What is Neutraceuticals"}
              description={
                "Cinnamon is a valuable spice that has so many health Benefits."
              }
              date={"September 18, 2023"}
            />
            <BlogCard
              image={`${process.env.PUBLIC_URL}/_resources/Img/Home/Sec-4 -- B3.jpg`}
              flexDirection={flexDirection}
              width={widthBlogCard2}
              blogNumber={"3"}
              title={"History of Cinnamon"}
              description={
                "Cinnamon is a valuable spice that has so many health Benefits."
              }
              date={"September 18, 2023"}
            />
            <button className="sec5--read--button">Read More Articles</button>
          </div>
        </div>
      </section> */}
      
      {/* Last Section */}
      <section className="home--sec6">
        <h3 className="title">OUR GALLERY</h3>
        <h1 className="subTitle">What We Do</h1>
        <div className="home--sec6--img">
          <div className="sec6--img--container" onClick={() => handleImageClick("Plantation Practices")}>
            <img
              src={`${process.env.PUBLIC_URL}/_resources/Img/Home/Sec-5 -- Plantation.jpg`}
              className="sec6--img"
              onClick={() => handleImageClick("Plantation")}
            />
            <p>Plantion Practices</p>
          </div>
          <div className="sec6--img--container" onClick={() => handleImageClick("Manufacturing")}>
            <img
              src={`${process.env.PUBLIC_URL}/_resources/Img/Home/Sec-5 -- Manufac.jpg`}
              className="sec6--img"
            />
            <p>Manufacturing</p>
          </div>
          <div className="sec6--img--container"  onClick={() => handleImageClick("Best Production Practices")}>
            <img
              src={`${process.env.PUBLIC_URL}/_resources/Img/Home/Sec-5 -- Best.jpg`}
              className="sec6--img"
            />
            <p>Production Best Practices</p>
          </div>
          <div className="sec6--img--container" onClick={() => handleImageClick("CSR Activities")}>
            <img
              src={`${process.env.PUBLIC_URL}/_resources/Img/Home/Sec-5 -- CSR.jpg`}
              className="sec6--img"
            />
            <p>CSR Activities</p>
          </div>
        </div>
      </section>
    </>
  );
}
