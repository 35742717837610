const productTypes = [
    {
        id: 1,
        title: "Cinnamon Bark Oil Range"
    },
    {
        id: 2,
        title: "Cut Cinnamon and Powder"
    },
    {
        id: 3,
        title: "Cinnamon Beverage Range"
    }
];

export default productTypes;