import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Header from "./components/header/header";
import Home from "./pages/home/home";
import About from "./pages/about/about";
import Contact from "./pages/contact/contact";
import Blog from "./pages/blog/blog";
import Gallery from "./pages/gallery/gallery";
import Products from "./pages/products/products";
import { useEffect, useState } from "react";
import Footer from "./components/footer/footer";
import "aos/dist/aos.css";
import Aos from "aos";

function App() {
  useEffect(()=>{
    Aos.init({
      duration: 500,
    })
  },[]);
  return (
    <Router>
      <Header />
      <Routes>
        <Route path={"/"} element={<Home />} />
        <Route path={"/products"} element={<Products/>} />
        <Route path={"/gallery"} element={<Gallery />} />
        <Route path={"/blog"} element={<Blog />} />
        <Route path={"/about"} element={<About />} />
        <Route path={"/contact"} element={<Contact />} />
      </Routes>
      <Footer/>
    </Router>
  );
}

export default App;
