import "./footer.css";
import { Link } from "react-router-dom";
import Logo from "../../_resources/icon/Logo.png";
import Youtube from "./_resources/Youtube.png";
import Facebook from "./_resources/Facebook.png";
import Instagram from "./_resources/Instagram.png";
import Linkdin from "./_resources/Linkdin.png";

export default function Footer() {

  const facebook = () => {
    window.location.href = 'https://web.facebook.com/jayceyloncinnamon?mibextid=LQQJ4d&rdid=T5iwevgPDM5YVMsu&share_url=https%3A%2F%2Fweb.facebook.com%2Fshare%2FTLyBBGkNK1mff7rq%2F%3Fmibextid%3DLQQJ4d%26_rdc%3D1%26_rdr';
  };

  const instagram = () => {
    window.location.href = 'https://www.instagram.com/jayceyloncinnamon?igsh=MWh4OGhzZGx4ZmI2cg==';
  };

  const youtube = () => {
    window.location.href = 'https://www.youtube.com/@JayCeylonCinnamon';
  };

  const linkdin = () => {
    window.location.href = 'https://www.linkedin.com/in/jay-ceylon-cinnamon-25a91a325/';
  };

  return (
    <>
      <div class="footer--sec">
        <div class="footer--sec2">
          <div class="footer--sec--div">
            <div class="footer--logo">
              <img src={Logo} />
            </div>
            <div class="footer--contactdtail">
              <span class="footer--malithi">Address:</span>
              <span>
                Kapuappale Estate, Hindurangala, Eheliyagoda, Sri Lanka. 
              </span>
              <br />
              <span class="footer--malithi">Contact:</span>
              <span>+94 452 266 424</span>
              <span>business@jaycinnamon.com</span>
            </div>
          </div>
          <div class="footer--sec--div2">
            <div class="footer--header">
              <Link to={"/"} className="footer--nav">
                Home
              </Link>
              <Link to={"/products"} class="footer--nav">
                Our Products
              </Link>
              <Link to="/gallery" class="footer--nav">
               Gallery
              </Link>
              {/* <Link to="/blog" class="footer--nav">
                Blog
              </Link> */}
              <Link to="/about" class="footer--nav">
                About
              </Link>
              <Link to="/contact" class="footer--nav">
                Contact Us
              </Link>
            </div>
          </div>
          <div class="footer--sec--div3">
            <div class="footer--txt">
              <span>Follow Us</span>
            </div>
            <div class="icon--div">
              <div class="icon">
                <img onClick={facebook} src={Facebook} />
              </div>
              <div class="icon">
                <img onClick={instagram} src={Instagram} />
              </div>
              <div class="icon">
                <img onClick={linkdin} src={Linkdin} />
              </div>
              <div class="icon">
                <img onClick={youtube} src={Youtube} />
              </div>
            </div>
          </div>
        </div>
        <div class="footer--line">
          <div class="footer--copyright">
            <span>
              Copyright © 2024 All Rights Reserved by&nbsp;Jay Ceylon Cinnamon (Pvt) Ltd.
            </span>
          </div>
          <div class="footer--outer">
            <a href="#home" class="footer--outTag">
              Privacy Policy
            </a>
            <a href="#home" class="footer--outTag">
              Terms of Service
            </a>
            <a href="#home" class="footer--outTag">
              Cookies Settings
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
