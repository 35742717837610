import "./about.css";
import Hero from "../../components/hero/hero";

export default function about() {
  return (
    <>
      <Hero
        img={`${process.env.PUBLIC_URL}/_resources/Img/About/About--Hero.jpg`}
        heading={"Welcome to"}
        heading2={"JayCinnamon"}
        subHeading={"best cinnamon ever"}
      />
      <section className="about--section">
        <h3 className="about--title" data-aos="fade-down">ABOUT US</h3>
        <h1 className="about--subTitle" data-aos="fade-down">Who We Are</h1>
        <div className="about--sec1Row" data-aos="fade-right">
          <div className="about--sec1RowContent">
            <p className="about--p1">
              We are a cinnamon processing company following the
              farmer-processor model in Hindurangala, Eheliyagoda area in Sri
              Lanka. <br />
              <br /> Our team harvests cinnamon from cultivations and at our
              factory, we process them. We also produce cinnamon-related
              value-added products while ensuring the best quality by following
              modern food safety practices. JCC has been in business since 2021.
            </p>
            <div className="about--sec1Details">
              <span className="about--sec1DetailPt1">Company Name</span>
              <span className="about--sec1DetailDash">&nbsp;-&nbsp;</span>
              <span className="about--sec1DetailPt2">
                Jay Ceylon Cinnamon (Pvt) Ltd
              </span>
            </div>
            <div className="about--sec1Details">
              <span className="about--sec1DetailPt1">Address</span>
              <span className="about--sec1DetailDash">&nbsp;-&nbsp;</span>
              <span className="about--sec1DetailPt2">
                Kapuappale Estate, Hindurangala, Eheliyagoda, Sri Lanka
              </span>
            </div>
            <div className="about--sec1Details">
              <span className="about--sec1DetailPt1">Contact Details</span>
              <span className="about--sec1DetailDash">&nbsp;-&nbsp;</span>
              <span className="about--sec1DetailPt2">
                {" "}
                +94 452 266 423 <br />
                +94 452 266 424
              </span>
            </div>
            <div className="about--sec1Details">
              <span className="about--sec1DetailPt1">
                Business Registration
              </span>
              <span className="about--sec1DetailDash">&nbsp;-&nbsp;</span>
              <span className="about--sec1DetailPt2">
                PV 00246350 dated 09.10.2021
              </span>
            </div>
          </div>
          <img
            src={`${process.env.PUBLIC_URL}/_resources/Img/About/Group 4to.jpg`}
            className="about--groupImg"
          />
        </div>
      </section>
      <section className="about--section">
        <h3
          className="about--title"
          style={{
            marginTop: "4vh",
          }}
           data-aos="fade-down"
        >
          Company Credentials
        </h3>
        <p className="about--desc" data-aos="fade-down">
          We are a limited liability company and we have obtained ISO-22000:2018
          Quality Certification.
        </p>
        <div className="about--certificates">
          <img
            src={`${process.env.PUBLIC_URL}/_resources/Img/About/Credintials 1.jpg`}
            className="about--certificate"
            data-aos="flip-left"
          />
          <img
            src={`${process.env.PUBLIC_URL}/_resources/Img/About/Credintials 2.jpg`}
            className="about--certificate"
            data-aos="flip-left"
          />
          <img
            src={`${process.env.PUBLIC_URL}/_resources/Img/About/Credintials 3.jpg`}
            className="about--certificate"
            data-aos="flip-left"
          />
          <img
            src={`${process.env.PUBLIC_URL}/_resources/Img/About/Credintials 4.jpg`}
            className="about--certificate"
            data-aos="flip-left"
          />
        </div>
      </section>
      <section className="about--banner" data-aos="slide-right">
        <h3
          className="about--title"
          style={{
            marginTop: "4vh",
          }}
        >
          Our Vision
        </h3>
        <p className="about--bannerTxt">
          “TO BECOME THE LEADING CINNAMON EXPORTER WITH MOST DIVERSIFIED PRODUCT
          PORTFOLIO IN SABARAGAMUWA PROVINCE IN SRI LANKA”.
        </p>
      </section>
      <section className="about--sec2"  data-aos="fade-in">
        <div className="about--sec2Row">
          <img
            src={`${process.env.PUBLIC_URL}/_resources/Img/About/Mr.Gehan.jpg`}
            className="about--gehan"
          />
          <div className="about--sec1RowContent">
            <h2 className="about--sec2Title">
              Mr. Gehan Athapatthu - Operations Manager
            </h2>
            <p className="about--p2">
              Gihan is an experienced professional specializing in Plantation
              agriculture who graduated from the Faculty of Agricultural
              Sciences, at Sabaragamuwa University of Sri Lanka.
              <br /><br/>
              He was the very first employee. He has been instrumental in
              bringing the company to its current state and obtaining ISO
              22000:2018 certification. He specializes in Quality Assurance,
              Quality Control aspects, and Product Development.
            </p>
            <div className="about--sec1Details">
              <span className="about--sec1DetailPt1">Mobile</span>
              <span className="about--sec1DetailDash">&nbsp;-&nbsp;</span>
              <span className="about--sec1DetailPt2">
                +94 76 9010 524 (WhatsApp)
              </span>
            </div>
            <div className="about--sec1Details">
              <span className="about--sec1DetailPt1">Email</span>
              <span className="about--sec1DetailDash">&nbsp;-&nbsp;</span>
              <span className="about--sec1DetailPt2">
                operations@jaycinnamon.com
              </span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
