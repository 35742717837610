import "./products.css";
import Hero from "../../components/hero/hero";
import ProductSet from "../../components/productSet/productSet";
import productTypes from "../../data/productTypes";
import productData from "../../data/productData";

export default function Products() {
    return(
        <>
        <Hero
            img={`${process.env.PUBLIC_URL}/_resources/Img/Products/Product--Hero.jpg`}
            heading={"Welcome to"}
            heading2={"JayCinnamon"}
            subHeading={"best cinnamon ever"}
        />
        <section className="productSection">
            {productTypes.map((PType,index) => {
            const dataSet = productData.filter((item) => item.type === parseInt(PType.id));
            console.log(PType.id)
            return(
                // <p>text</p>
            <ProductSet
                title={PType.title}
                products={dataSet}
            />
            )
        })}
        </section>
        
        </>
    );
}