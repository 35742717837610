import React from 'react';
import './productCard.css';

const ProductCard = ({ image, title, description, price }) => {
    return (
        <div className="product-card">
            <img src={`${process.env.PUBLIC_URL}/_resources/Img/Products/${image}`} alt={title} className="product-image" />
            <h3 className="product-title">{title}</h3>
            {/* <p className="product-description">{description}</p> */}
            <p className="product-price">{price} LKR</p>
        </div>
    );
};

export default ProductCard;
