import "./gallery.css";
import Hero from "../../components/hero/hero";
import GallerySec from "../../components/gallerySection/gallerySection";
import GalleryData from "../../data/galleryData";
import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

export default function Gallery() {
  const location = useLocation(); // Correct usage of useLocation
  const section = location.state?.section; // Get the section name from state
  const sectionRefs = useRef([]); // Create refs array for each section

  useEffect(() => {
    if (section && sectionRefs.current) {
      const sectionIndex = GalleryData.findIndex(
        (dataSet) => dataSet.title === section
      );
      if (sectionIndex !== -1 && sectionRefs.current[sectionIndex]) {
        sectionRefs.current[sectionIndex].scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [section]);
  return (
    <>
      <Hero
        img={`${process.env.PUBLIC_URL}/_resources/Img/Gallery/Gallery--Hero.jpg`}
        heading={"Welcome to"}
        heading2={"JayCinnamon"}
        subHeading={"best cinnamon ever"}
      />
      <div className="gallery--titleRow">
        <h3 className="title">OUR GALLERY</h3>
        <h1 className="subTitle">Start Your Transformation</h1>

        {GalleryData.map((dataSet, index) => {
          console.log(index);
          return (
            <GallerySec
              id={index}
              title={dataSet.title}
              banner={dataSet.banner}
              imgs={dataSet.imgs}
              ref={(el) => (sectionRefs.current[index] = el)}
            />
          );
        })}
      </div>
    </>
  );
}
