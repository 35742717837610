import "../hero/hero.css";
import ScrollToTopOnMount from "../scrollToTop";

export default function Hero({ img, heading, heading2, subHeading }) {
  return (
    <>
      <ScrollToTopOnMount />
      <div className="hero" style={{ backgroundImage: `url(${img})` }}>
        <div className="hero--row1">
          <h2 className="hero--heading">{heading}</h2>
        </div>
        <div className="hero--row1">
          <h2 className="hero--headingPt2">{heading2}</h2>
        </div>
        <div className="hero--row2">
          <h5 className="hero--subHeading">{subHeading}</h5>
        </div>
        <div className="hero--row3">
          <button className="hero--btn">Buy some cinnamon</button>
        </div>
      </div>
    </>
  );
}
