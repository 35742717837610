const productData = [

    {
        id: 1,
        title : `Cinnamon Bark Oil 15 ml`,
        desc : `Our cinnamon bark oil is extracted through steam distillation under hygienic conditions and bottled mechanically.`,
        img : `Bark-15ml-1.jpg`,
        price : `2500`,
        type : 1
    },
    {
        id: 2,
        title : `Cinnamon Bark Oil 30 ml`,
        desc : `Our cinnamon bark oil is extracted through steam distillation under hygienic conditions and bottled mechanically.`,
        img : `Bark-30ml-1.jpg`,
        price : `4300`,
        type : 1
    },
    {
        id: 3,
        title : `Alba Cut Cinnamon 5" - 100g`,
        desc : `Our Cut Cinnamon is produced at an ISO 22000:2018 certified company under hygienic conditions.`,
        img : `Cut-Alba-1.jpg`,
        price : `1500`,
        type : 2
    },
    {
        id: 4,
        title : `C4 Cut Cinnamon 5" - 100g`,
        desc : `Our Cut Cinnamon is produced at an ISO 22000 certified company under hygienic conditions.`,
        img : `Cut-C4-1.jpg`,
        price : `1070`,
        type : 2
    },
    {
        id: 5,
        title : `C5 Cut Cinnamon 5" - 100g`,
        desc : `Our Cut Cinnamon is produced at an ISO 22000 certified company under hygienic conditions.`,
        img : `Cut-C5-1.jpg`,
        price : `1150`,
        type : 2
    },
    {
        id: 6,
        title : `C5 Special Cinnamon 5" - 100g`,
        desc : `Our Cut Cinnamon is produced at an ISO 22000 certified company under hygienic conditions.`,
        img : `Cut-C5-Special-1.jpg`,
        price : `1260`,
        type : 2
    },
    {
        id: 7,
        title : `Cinnamon Powder - 100g`,
        desc : `Our Cinnamon Powder is produced at an ISO 22000 certified company under hygienic conditions.`,
        img : `Cut-Powder-1.jpg`,
        price : `1000`,
        type : 2
    },
    {
        id: 8,
        title : `Cinnamon Bael Fruit Tea`,
        desc : `Explore the extraordinary fusion of nature’s kindness and exquisite taste in our Cinnamon Bael tea. This exquisite blend combines the spicey cinnamon with the gentle sweetness of Bael fruit that tantalizes your senses and nurtures your well-being.`,
        img : `Bvg-Friut-1.jpg`,
        price : `770`,
        type : 3
    },
    {
        id: 9,
        title : `Cinnamon Black Tea`,
        desc : `Enjoy the enchanting blend of premium black tea and aromatic cinnamon blend. This infusion brings about the richness of black tea and the spice of cinnamon, offering a tea experience that stimulates your senses and warms your soul.`,
        img : `Bvg-Black-1.jpg`,
        price : `1900`,
        type : 3
    },
    {
        id: 10,
        title : `Cinnamon Cardamom Tea`,
        desc : `Enjoy a captivating melody of spice and serenity that transports you to evoke the soothing embrace of spice-laden traditions. This unique infusion marries the aromatic essence of cinnamon creating a tea that elevates your tea experience.`,
        img : `Bvg-Cardomon-1.jpg`,
        price : `590`,
        type : 3
    },
    {
        id: 11,
        title : `Cinnamon Coffee`,
        desc : `A comforting embrace of freshly brewed coffee that meets the warming allure of cinnamon. Immerse yourself in the symphony of aromas and tastes that redefine your coffee experience. Introducing our Cinnamon Coffee.`,
        img : `Bvg-Coffee-1.jpg`,
        price : `830`,
        type : 3
    },
    {
        id: 12,
        title : `Cinnamon Ginger Tea`,
        desc : `Prepare for a journey of rich and vibrant flavors with our exquisite blend of cinnamon and ginger tea which produces warm, spicy, and zesty notes while also energizing and invigorating you for better health.`,
        img : `Bvg-Ginger-1.jpg`,
        price : `570`,
        type : 3
    },
    {
        id: 13,
        title : `Cinnamon Green Tea`,
        desc : `Elevate your tea experience with our healthy cinnamon green tea, which is rich in antioxidants, promotes digestive comfort, boosts immunity, and helps regulate blood sugar levels. Enjoy the taste of cinnamon infused green tea in every sip with a touch of nostalgia.`,
        img : `Bvg-Green-1.jpg`,
        price : `590`,
        type : 3
    },
    {
        id: 14,
        title : `Cinnamon Lemongrass Tea`,
        desc : `A zestful harmony of flavors with a zesty freshness of lemon-grass infusion produces a splendid blend that invigorates your senses and nurtures your well-being.`,
        img : `Bvg-Lemon-1.jpg`,
        price : `600`,
        type : 3
    },
    {
        id: 15,
        title : `Cinnamon Tamarind Tea - Mild`,
        desc : `Elevate your tea moments with our enhancing cinnamon tamarind tea. The essence of mild yet captivating flavor marries the comforting allure of cinnamon creating a soothing symphony of taste and well-being.`,
        img : `Bvg-Tamarind-Mild-1.jpg`,
        price : `650`,
        type : 3
    },
    {
        id: 16,
        title : `Cinnamon Tamarind  Tea - Strong`,
        desc : `Elevate your tea moments with our enhancing cinnamon tamarind tea. A bold mind-capturing blend that will awaken your senses and tantalize your palate. The tea is crafted to produce a strong flavor of tamarind and spiciness of cinnamon
        Embrace wellness with every soothing sip.`,
        img : `Bvg-Tamarind-Strong-1.jpg`,
        price : `650`,
        type : 3
    },
    {
        id: 17,
        title : `Cinnamon Turmeric Tea`,
        desc : `Enter a journey of flavors with our Cinnamon Turmeric tea. A unique elixir that is blended to invigorate your senses and nurture your health. The infusion of cinnamon and turmeric is celebrated for its centuries-old tradition of enhancing both taste and wellness.`,
        img : `Bvg-Turmeric-1.jpg`,
        price : `520`,
        type : 3
    },
    {
        id: 18,
        title : `Cinnamon Vanilla Tea`,
        desc : `Experience the rich notes of cinnamon harmonized with the sweetness and soothing flavor of vanilla. This delightful infusion is a symphony of flavors that will stimulate your senses and teleport you to a surrounding of bliss with every sip.`,
        img : `Bvg-Vanila-1.jpg`,
        price : `690`,
        type : 3
    },
    {
        id: 19,
        title : `Pure Cinnamon Tea - Mild`,
        desc : `Embrace wellness with every soothing sip. A delicate blend that promises a subtle yet soothing journey for your senses with the remarkable combination of cinnamon that envelops your palate.`,
        img : `Bvg-Pure-Mild-1.jpg`,
        price : `810`,
        type : 3
    },
    {
        id: 20,
        title : `Pure Cinnamon Tea - Strong`,
        desc : `Embrace wellness with every soothing sip. Crafted for those who crave an intense and robust flavor of the cinnamon experience this tea is an exquisite blend designed to awaken your senses and leave a mark on your palate.`,
        img : `Bvg-Pure-Strong-1.jpg`,
        price : `830`,
        type : 3
    }

];

export default productData;