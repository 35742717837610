import "./blog.css";
import Hero from "../../components/hero/hero";
import heroImg from "../../_resources/img/hero.png";

export default function blog() {
    return(
        <>
<Hero
            img={heroImg}
            heading={"Welcome to"}
            heading2={"JayCinnamon"}
            subHeading={"best cinnamon ever"}
        />
        </>
    )
}