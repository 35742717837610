import { useEffect, useState } from "react";
import "./form.css";

function Form() {
  const [nname, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [response, setResponse] = useState("");

  var messageTimeout = null;

  function showMessage(message){
    const msgClassList = document.getElementById("formMessage").classList;
    clearInterval(messageTimeout);
    setResponse("");
    msgClassList.remove("show");

    messageTimeout = setTimeout(() => {
      setResponse("");
      msgClassList.remove("show");
    }, 2000);
    setResponse(message);
    msgClassList.add("show");
  }

  function handleResponse(data) {
    var statusCode = data.actionStatus;
    switch (statusCode) {
      case 2:
        showMessage(data.message);
        break;
      case 1:
        showMessage(data.message);
        setName("");
        setEmail("");
        setMessage("");
        break;
      case 0:
        showMessage(data.message);
        break;
      default:

        break;
    }
  }

  function sendMessage() {
    const dataSet = {
      name: nname,
      email: email,
      message: message,
    };

    try{
      fetch("https://jaycinnamon.com/api/sendMail.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataSet),
      })
        .then((response) => response.json())
        .then((data) => {
          handleResponse(data);
        })
        .catch((error) => {
          console.error("Server error:", error);
          // showMessage("Connection error. Please try again later");
        });
    }catch(e){
      console.error("Unexpexted error :", e);
      showMessage("An unexpected error occured. Please reload the page.");
    }
  }

  return (
    <>
      <div className="form">
        <p className="form--message" id="formMessage">{response}</p>
        <div className="form--col">
          <label className="form--label">Name</label>
          <input
            className="form--input"
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </div>
        <div className="form--col">
          <label className="form--label">Email</label>
          <input
            className="form--input"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div>
        <div className="form--col" id="col5">
          <label className="form--label">Message</label>
          <textarea
            className="form--textarea"
            onChange={(e) => {
              setMessage(e.target.value);
            }}
          ></textarea>
        </div>
        <div className="form--btnRow">
        <button className="form--btn" onClick={sendMessage}>
          Submit
        </button>
        </div>
      </div>
    </>
  );
}

export default Form;
