import "./contact.css";
import Hero from "../../components/hero/hero";
import Form from "../../components/contactForm/form";
import Icon1 from "../../_resources/icon/Email.png";
import Icon2 from "../../_resources/icon/Phone.png";
import Icon3 from "../../_resources/icon/location.png";

export default function contact() {
    return(
        <>
<Hero
            img={`${process.env.PUBLIC_URL}/_resources/Img/Contact/Contact--Hero.jpg`}
            heading={"Welcome to"}
            heading2={"JayCinnamon"}
            subHeading={"best cinnamon ever"}
        />
        <div className="contact--girlfriends">
            <span className="contact--txt1">Contact Us</span>
            <span className="contact--txt2">Stay in Touch</span>
        </div>
        <div className="contact--malithi">
        <div className="contact--tashi" data-aos="fade-right">
            <div className="contact--Hashi">
            <img src={Icon1} style={{
                width: "32px"
            }}/>
            <span className="contact--txt3">Email</span>
            <span className="contact--txt4">Contact us via Email</span>
            <span className="contact--txt4">business@jaycinnamon.com</span>
            </div>
            <div className="contact--Hashi">
            <img src={Icon2} style={{
                width: "32px"
            }}/>
            <span className="contact--txt3">Phone</span>
            <span className="contact--txt4">Contact us via Phone Call</span>
            <span className="contact--txt4">Tel: +94 452 266 423 / +94 452 266 424</span>
            </div>
            <div className="contact--Hashi">
            <img src={Icon3} style={{
                width: "32px"
            }}/>
            <span className="contact--txt3">Office</span>
            <span className="contact--txt4">Visit Our Office</span>
            <span className="contact--txt4">Kapuappale Estate, Hindurangala, Eheliyagoda, Sri Lanka.</span>
            </div>
        </div>
        <div className="contact--sayuu" data-aos="fade-down">
        <Form/>
        </div>
        </div>
        <div className="contact--sadi">
        <iframe
        title="Google Map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3961.9305964380183!2d80.25435807571398!3d6.778301720148451!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae3b16aa1eb59b3%3A0x81e5b55b5163c856!2sJay%20Ceylon%20Cinnamon%20(Pvt)%20Ltd!5e0!3m2!1sen!2slk!4v1724498417556!5m2!1sen!2slk"
        width="100%"
        height="100%"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
        </div>
        </>
    )
}