import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { Navigation } from "swiper/modules";
import "./gallerySection.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import React, { forwardRef } from "react";

function GallerySec({ id, banner, title, imgs },ref) {
  return (
    <div className="gallerySec" ref={ref}>
      <div
        className="gallerySec--banner"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/_resources/Img/Gallery/${banner})`,
        }}
      >
        <div className="gallerySec--overlay">
          <h2 className="gallerySec--title">{title}</h2>
        </div>
      </div>
      <div className="gallerySec--sliderRow">
        <button className={`gallerySec--paginationBtn gallerySec--prev${id}`}>
          <FontAwesomeIcon icon={faAngleLeft} />
        </button>
        <Swiper
          modules={[Navigation]}
          spaceBetween={5}
          slidesPerView={1}
          navigation={{
            prevEl: `.gallerySec--prev${id}`,
            nextEl: `.gallerySec--next${id}`,
          }}
          breakpoints={{
            540: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 4,
            },
          }}
          className="gallerySec--slider"
        >
          {imgs.map((img) => {
            return (
              <SwiperSlide>
                <div className="gallerySec--slide">
                  <img
                    className="gallerySec--slideImg"
                    loading="lazy"
                    src={`${process.env.PUBLIC_URL}/_resources/Img/Gallery/${img}`}
                  />
                  <div className="swiper-lazy-preloader"></div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
        <button className={`gallerySec--paginationBtn gallerySec--next${id}`}>
          <FontAwesomeIcon icon={faAngleRight} />
        </button>
      </div>
    </div>
  );
}

export default forwardRef(GallerySec);